import { CalendarTime } from "./calendar_time";
import { nextFrame, triggerEvent } from "../helpers";

const OPTIONS = function(format) {
  const times = []

  if (format == "twelve_hour") {
    ["am", "pm"].forEach((p) => {
      Array.from([12].concat([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])).forEach((i) => {
        ["00", "30"].forEach((m) => times.push(`${i}:${m}${p}`))
      })
    })
  } else {
    for (let hour = 0; hour < 24; hour++) {
      ["00", "30"].forEach((minute) => times.push(`${hour}:${minute}`))
    }
  }

  return times
}

export class TimeInputController {
  constructor(element) {
    this.didFocusInput = this.didFocusInput.bind(this)
    this.didChangeInput = this.didChangeInput.bind(this)
    this.didPressKey = this.didPressKey.bind(this)
    this.didClick = this.didClick.bind(this)
    this.didClickOption = this.didClickOption.bind(this)
    this.element = element
    this.format = this.element.format
    this.input = this.element.input
    this.installClickListener()
    this.installInputListeners()
  }

  destroy() {
    this.uninstallClickListener()
    this.uninstallInputListeners()
  }

  async didFocusInput() {
    await nextFrame()
    this.input.select()
    this.revealOptions()
    this.highlightCurrentOptionElement()
  }

  didChangeInput() {
    this.updateParsedTime()
  }

  didPressKey(event) {
    switch (event.key) {
      case "Tab":
      case "Escape":
        this.hideOptions()
        event.stopPropagation()
        break
      case "ArrowDown":
        this.revealOptions()
        this.selectNextOption()
        break
      case "ArrowUp":
        this.revealOptions()
        this.selectPreviousOption()
        break
      case "Enter":
        this.updateParsedTime()
        event.preventDefault()
        break
    }
  }

  didClick(event) {
    if (this.element.contains(event.target)) {
      return event.preventDefault()
    } else {
      return this.hideOptions()
    }
  }

  didClickOption(event) {
    if (event.target.getAttribute("data-role") === "option") {
      this.selectOption(event.target)
      this.hideOptions()
    }
  }

  // Private

  installClickListener() {
    return window.addEventListener("click", this.didClick)
  }

  uninstallClickListener() {
    return window.removeEventListener("click", this.didClick)
  }

  installInputListeners() {
    this.input.addEventListener("focus", this.didFocusInput)
    this.input.addEventListener("change", this.didChangeInput)
    return this.input.addEventListener("keydown", this.didPressKey)
  }

  uninstallInputListeners() {
    this.input.removeEventListener("focus", this.didFocusInput)
    this.input.removeEventListener("change", this.didChangeInput)
    return this.input.removeEventListener("keydown", this.didPressKey)
  }

  parseTimeFromInput() {
    let time
    try {
      if (this.input.value) time = CalendarTime.parse(this.input.value)
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
    return time
  }

  hideOptions() {
    if (this.optionsElement != null) {
      this.optionsElement.style.display = "none"
    }
  }

  revealOptions() {
    if (this.optionsElement == null) {
      this.optionsElement = this.createAndPopulateOptionsElement()
      this.element.appendChild(this.optionsElement)
      this.optionsElement.addEventListener("click", this.didClickOption)
    }
    this.optionsElement.style.display = "block"
  }

  updateParsedTime() {
    this.element.time = this.parseTimeFromInput() || CalendarTime.parse(this.element.value)
    this.hideOptions()
    this.element.dispatchEvent(new Event("time-change", { bubbles: true }))
  }

  createAndPopulateOptionsElement() {
    // Need two elements (wrapper + list) in order to workaround a Safari rendering bug
    // that makes the first overflowed element clickable even though it's hidden
    const optionsElement = document.createElement("div")
    optionsElement.classList.add("time-picker__options-wrapper")
    optionsElement.innerHTML = "<ul class='time-picker__options list--unbulleted flush'></ul>"
    this.optionsList = optionsElement.firstChild

    Array.from(OPTIONS(this.format)).forEach((option) => {
      const optionElement = document.createElement("li")
      const itemElement = document.createElement("a")
      itemElement.classList.add("time-picker__item")
      itemElement.setAttribute("data-role", "option")
      itemElement.textContent = option
      itemElement.dataset.option = CalendarTime.parse(option)
      itemElement.dataset.listSelectionTarget = "item"
      itemElement.setAttribute("href", "#")

      optionElement.appendChild(itemElement)
      this.optionsList.appendChild(optionElement)
    })

    return optionsElement
  }

  scrollToCurrentOption() {
    if (this.optionsList != null) {
      this.optionsList.scrollTop = this.scrollOffsetToCurrentOption()
    }
  }

  scrollOffsetToCurrentOption() {
    const currentOptionElement = this.findCurrentOptionElement()
    if (currentOptionElement) {
      const currentOptionElementHeight = currentOptionElement.getBoundingClientRect().height
      return currentOptionElement.offsetTop - currentOptionElementHeight
    }
  }

  selectOption(optionElement) {
    this.element.time = CalendarTime.parse(optionElement.textContent)
    this.highlightCurrentOptionElement()
    triggerEvent(this.element, "change")
  }

  selectNextOption() {
    const nextOptionElement = this.findNextOptionElement()
    if (nextOptionElement) {
      this.selectOption(nextOptionElement)
    }
  }

  findNextOptionElement() {
    return this.findCurrentOptionElement()?.parentElement?.nextElementSibling?.querySelector("[data-role=option]")
  }

  selectPreviousOption() {
    const previousOptionElement = this.findPreviousOptionElement()
    if (previousOptionElement) {
      this.selectOption(previousOptionElement)
    }
  }

  findPreviousOptionElement() {
    return this.findCurrentOptionElement()?.parentElement?.previousElementSibling?.querySelector("[data-role=option]")
  }

  findCurrentOptionElement() {
    if (this.element.time != null) {
      const roundedTime = this.element.time.round()
      return this.optionsList?.querySelector(`a[data-option='${roundedTime.toString()}']`)
    }
  }

  highlightCurrentOptionElement() {
    const currentOptionElement = this.findCurrentOptionElement()
    this.clearHighlightFromOptionElements()
    if (currentOptionElement) {
      this.element.dataset.listSelectionIndex = Array.from(this.optionElements).indexOf(currentOptionElement)
      this.scrollToCurrentOption()
      currentOptionElement.classList.add("time-picker__item--selected")
    }
  }

  clearHighlightFromOptionElements() {
    return this.optionElements.forEach((optionElement) => optionElement.classList.remove("time-picker__item--selected"))
  }

  get optionElements() {
    return this.element.querySelectorAll("[data-role=option]")
  }
}
