import { pad } from "../helpers"

const PATTERN = /^\s*(\d{1,2})(?:[.:]?([0-5]\d?)?)?(?:[.:]?([0-5]\d?)?)?(?:\s*([ap])(?:\.?m\.?)?|\s*[h]?)?\s*$/i

export class CalendarTime {
  static parse(time) {
    var _, am, ampm, hour, match, minute, pm, second, timeString
    timeString = "" + time
    if (time instanceof Date) {
      hour = time.getHours()
      minute = time.getMinutes()
      second = time.getSeconds()
      return new this(hour, minute, second)
    } else if (match = timeString.match(PATTERN)) {
      _ = match[0], hour = match[1], minute = match[2], second = match[3], ampm = match[4]
      hour = parseInt(hour, 10)
      minute = parseInt(minute != null ? minute : "0", 10)
      second = parseInt(second != null ? second : "0", 10)
      am = ampm != null ? ampm.match(/a/i) : void 0
      pm = ampm != null ? ampm.match(/p/i) : void 0
      if ((1 <= hour && hour <= 11) && pm) {
        hour += 12
      }
      if (hour === 12 && am) {
        hour = 0
      }
      return new this(hour, minute, second)
    } else {
      throw Error("invalid time (" + time + ")")
    }
  }

  static fromDate(date) {
    return new this(date.getHours(), date.getMinutes(), date.getSeconds())
  }

  static isValid(time) {
    try {
      this.parse(time)
      return true
    } catch (error) {
      return false
    }
  }

  constructor(hour1, minute1, second1) {
    let ref, ref1, ref2
    this.hour = hour1 != null ? hour1 : 0
    this.minute = minute1 != null ? minute1 : 0
    this.second = second1 != null ? second1 : 0
    if (!((0 <= (ref = this.hour) && ref <= 23) && (0 <= (ref1 = this.minute) && ref1 <= 59) && (0 <= (ref2 = this.second) && ref2 <= 59))) {
      throw Error("invalid time (" + this.hour + ", " + this.minute + ", " + this.second + ")")
    }
    this.ampm = this.hour < 12 ? "am" : "pm"
    if (this.hour === 0) {
      this.hour12 = 12
    } else if (this.hour > 12) {
      this.hour12 = this.hour - 12
    } else {
      this.hour12 = this.hour
    }
  }

  toString(options) {
    var ms
    if (options == null) {
      options = {}
    }
    ms = [ pad(this.minute), pad(this.second) ]
    if (options.short && this.second === 0) {
      ms.pop()
    }
    if (ms.length) {
      ms = ":" + (ms.join(':'))
    }
    if (options["12"]) {
      return "" + this.hour12 + ms + this.ampm
    } else {
      return "" + this.hour + ms
    }
  }

  toFriendlyString() {
    return this.toString({
      12: true,
      short: true
    })
  }

  valueOf() {
    return this.hour * 10000 + this.minute * 100 + this.second
  }

  round() {
    if (this.minute === 0) {
      return this
    }

    if (this.minute < 30) {
      return new CalendarTime(this.hour, 30, 0);
    }

    let newHour = (this.hour + 1) % 24;
    return new CalendarTime(newHour, 0, 0);
  }
}
